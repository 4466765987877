@import "./helpers/variables.scss";

.content-over-banner {
  background-color: $white;
  margin: 0 auto;
  max-width: $grid-center-column-width;
  padding: 1.5rem 2rem 3rem;
  position: relative;
  width: 100%;
  z-index: $site-copy-z-index;

  @supports (display: grid) {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
  }

  a {
    color: $base-font-color;
    font-weight: normal;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.content-over-banner__title {
  font-size: 1.75rem;

  &.content-over-banner__title--no-margin {
    margin-bottom: 0;
  }
}

.content-over-banner__small-title {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  margin-top: 0.25rem;
}

.content-over-banner__button-container {
  margin-top: 2rem;
  text-align: center;
}
