@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700');

// Colors
$blue--dark: #28507a;
$blue--medium: #025c9d;
$blue--light: #bad5ef;
$green: #16627e;
$purple: #2F3375;
$gray--dark: #333;
$gray--medium: #666A73;
$gray--light: #ddd;
$white: #fff;
$black: #000;

$calendly-gray: $gray--medium;
$base-font-color: $gray--dark;
$action-color: $blue--medium;
$action-color--hover: $purple;
$focus-outline-color: $blue--light;
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;
$viewport-background-color: $white;

// Typography
$base-font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;
$heading-font-family: $base-font-family;
$base-line-height: 1.6;
$heading-line-height: 1.3;
$bold-font-weight: 700;
$semibold-font-weight: 600;
$base-font-weight: 400;

// Animations
$hover-duration: 150ms;
$hover-timing: ease;

// Borders
$border-default-radius: 3px;
$border-default-color: $gray--light;
$border-default-width: 1px;
$border-default: $border-default-width solid $border-default-color;

// Forms
$form-background-color: $white;
$form-box-shadow: inset 0 1px 3px rgba($black, 0.06);
$form-box-shadow--focus: $form-box-shadow, 0 0 5px rgba($action-color, 0.7);

// Site Header
$site-header-height: 4rem;

// Mobile Menu
$mobile-menu-width: 240px;
$mobile-menu-duration: 400ms;
$mobile-menu-timing: ease;

// Sizes
$grid-center-column-width: 73ch;
$grid-top-row-height: 275px;
$full-width-banner-height: $grid-top-row-height + 100px;

// z Indexes
$hidden-z-index: -1;
$base-z-index: 0;
$full-width-banner-z-index: 0;
$site-copy-z-index: 1;
$site-header-z-index: 10;
$pop-up-z-index: 15;
