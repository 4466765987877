@import "./helpers/breakpoints.scss";
@import "./helpers/variables.scss";

.header-over-banner {
  // sass-lint:disable-block property-units
  margin: -300px auto 0;
  max-width: $grid-center-column-width;
  padding: 0 1rem;
  position: relative;
  z-index: $site-copy-z-index;

  @include breakpoint($for-grid-breakpoint-up) {
    @supports(display: grid) {
      align-self: end;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      margin: 0;
    }
  }
}

.header-over-banner__logo {
  height: auto;
  width: 470px;
}

.header-over-banner__title {
  color: $white;
  font-size: 1.85rem;
  font-weight: $bold-font-weight;
  margin: 1rem 1rem 1.5rem 1rem;
  max-width: 24ch;
  text-shadow: 0 0 10px rgba($blue--dark, 0.6);

  @include breakpoint($for-grid-breakpoint-up) {
    font-size: 2.25rem;
  }
}
