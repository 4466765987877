@import "./helpers/variables.scss";

.site-footer {
  background-color: $blue--dark;
  color: $white;
  font-size: 0.85rem;
  padding: 0.75rem 1rem;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}
