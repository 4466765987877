@import "../helpers/breakpoints.scss";
@import "../helpers/variables.scss";

html {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 16px;
  font-weight: $base-font-weight;
  line-height: $base-line-height;

  @include breakpoint($for-grid-breakpoint-up) {
    font-size: 18px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $blue--medium;
  font-family: $heading-font-family;
  font-weight: $semibold-font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $heading-line-height;
  margin: 0;
}

h1,
h2,
h3 {
  margin-bottom: 1rem;
}

h4,
h5,
h6 {
  margin-bottom: 0.25rem;
}

p {
  margin: 0 0 1rem;
}

a {
  color: $action-color;
  font-weight: $semibold-font-weight;
  text-decoration-skip: ink;
  transition: color $hover-duration $hover-timing;

  &:focus {
    outline: none;
    outline-offset: $focus-outline-offset;
  }

  &:hover {
    color: $action-color--hover;
  }
}

hr {
  border-bottom: $border-default;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1rem 0;
}

address {
  font-style: normal;
}
