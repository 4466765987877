@import "../helpers/variables.scss";

table {
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  text-align: left;
  width: 100%;
}

thead {
  line-height: $heading-line-height;
  vertical-align: bottom;
}

tbody {
  vertical-align: top;
}

tr {
  border-bottom: $border-default;
}

th {
  font-weight: 600;
}

th,
td {
  padding: 0.5rem 0.5rem 0.5rem 0;
}
