@import "../helpers/variables.scss";

button,
.button {
  appearance: none;
  background-color: $white;
  border: 2px solid $action-color;
  border-radius: 9999px;
  color: $action-color;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $bold-font-weight;
  line-height: 1;
  padding: 0.75em 2.75em;
  text-align: center;
  text-decoration: none;
  transition: all $hover-duration $hover-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:focus {
    background-color: $blue--light;
    border-color: $blue--light;
    outline: none;
  }

  &:hover {
    background-color: $action-color;
    border-color: $action-color;
    color: $white;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $white;
    }
  }
}
