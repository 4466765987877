@import "../helpers/variables.scss";

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: 0.25rem;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: 16px;
}

input {
  appearance: none;
  background-color: $form-background-color;
  border: $border-default;
  border-radius: $border-default-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  padding: 0.15em;
  transition: border-color $hover-duration $hover-timing;
  width: 100%;

  &:hover {
    border-color: $action-color--hover;
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow--focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($form-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $border-default;
    }
  }

  &::placeholder {
    color: rgba($base-font-color, 0.5);
  }
}

textarea {
  resize: vertical;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: 0.25em;
}

input[type="file"] {
  margin-bottom: 0.25rem;
  width: 100%;
}

select {
  margin-bottom: 0.25rem;
  width: 100%;
}

input[type="checkbox"],
input[type="radio"],
input[type="file"],
select {
  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }
}
