@import "./breakpoints.scss";
@import "./variables.scss";

@mixin grid {
  @include breakpoint($for-grid-breakpoint-up) {
    @supports(display: grid) {
      display: grid;
      grid-template-columns: 1fr minmax(auto, $grid-center-column-width) 1fr;
      grid-template-rows: $grid-top-row-height 100px auto;
    }
  }
}
