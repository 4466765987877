@import "./helpers/stripped-button.scss";
@import "./helpers/variables.scss";

.enroll-pop-up {
  align-items: center;
  background-color: rgba($blue--dark, 0.8);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $hidden-z-index;

  .enroll-pop-up--is-open & {
    display: flex;
    z-index: $pop-up-z-index;
  }
}

.enroll-pop-up--is-open {
  height: 100%;
  overflow: hidden;
}

.enroll-pop-up__close-button {
  @include stripped-button;
  fill: $calendly-gray;
  height: 25px;
  width: 25px;
  float: left;

  &:focus {
    outline: 2px solid $blue--light;
    outline-offset: 4px;
  }

  &:hover {
    fill: $action-color;
  }

  svg {
    height: auto;
    width: 100%;
  }
}

.enroll-pop-up__form-container {
  background-color: $white;
  box-shadow: 0 0 10px rgba(darken($blue--dark, 10%), 0.8);
  padding: 1rem;
  width: 80vw;
  min-width: 360px;
  max-width: 1200px
}

.enroll-pop-up__title {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.enroll-pop-up__description {
  text-align: center;
}
