@mixin stripped-button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  font-weight: normal;
  line-height: inherit;
  padding: 0;
  text-decoration: none;

  &:focus {
    background-color: transparent;
    color: inherit;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}
