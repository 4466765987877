@import "./helpers/breakpoints.scss";
@import "./helpers/stripped-button.scss";
@import "./helpers/variables.scss";

.site-navigation {
  background-color: darken($blue--dark, 6%);
  height: 100%;
  position: fixed;
  right: -($mobile-menu-width);
  top: 0;
  transition: all $mobile-menu-duration $mobile-menu-timing;
  width: $mobile-menu-width;

  @include breakpoint($for-large-navigation-up) {
    background-color: transparent;
    position: static;
    transition: none;
    width: auto;
  }

  .site-header--menu-is-open & {
    right: 0;
  }

  .site-navigation__enroll-button {
    @include stripped-button;

    &:focus {
      color: $blue--light;
    }
  }

  a,
  .site-navigation__enroll-button {
    color: $white;
    display: block;
    font-weight: $semibold-font-weight;
    padding: 1rem;

    @include breakpoint($for-large-navigation-up) {
      display: inline-block;
    }
  }

  li {
    @include breakpoint($for-large-navigation-up) {
      display: inline-block;
    }
  }

  .site-navigation__contact-number {
    display: none;
    font-size: 1.25rem;
    font-weight: $semibold-font-weight;

    .site-header--menu-is-open & {
      display: block;

      @include breakpoint($for-large-navigation-up) {
        display: none;
      }
    }
  }
}
