@import "./helpers/variables.scss";

.full-width-banner {
  background-position: center right;
  background-size: cover;
  grid-column: 1 / 4;
  grid-row: 1 / 3;
  height: $full-width-banner-height;
  position: relative;
  width: 100%;
  z-index: $full-width-banner-z-index;

  &::before {
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &--blue::before {
    background-color: rgba($blue--medium, 0.5);
  }

  &--green::before {
    background-color: rgba($green, 0.5);
  }

  &--purple::before {
    background-color: rgba($purple, 0.5);
  }
}
