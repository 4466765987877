@import "../helpers/variables.scss";

$mobile-breakpoint: 500px;
$tablet-breakpoint: 768px;
$laptop-breakpoint: 1024px;
$desktop-breakpoint: 1440px;
$monitor-breakpoint: 2560px;
$grid-breakpoint: $grid-center-column-width;
$for-mobile-up: "min-width: #{$mobile-breakpoint}";
$for-tablet-up: "min-width: #{$tablet-breakpoint}";
$for-laptop-up: "min-width: #{$laptop-breakpoint}";
$for-desktop-up: "min-width: #{$desktop-breakpoint}";
$for-monitor-up: "min-width: #{$monitor-breakpoint}";
$for-large-navigation-up: $for-tablet-up;
$for-grid-breakpoint-up: "min-width: #{$grid-breakpoint}";

@mixin breakpoint($size) {
  @media ($size) { @content; }
}

// // usage
// .my-box {
//   padding: 10px;

//   @include breakpoint($for-desktop-up) {
//     padding: 20px;
//   }
// }

// @include breakpoint($for-mobile-up) {}
// @include breakpoint($for-tablet-up) {}
// @include breakpoint($for-laptop-up) {}
// @include breakpoint($for-desktop-up) {}
// @include breakpoint($for-monitor-up) {}
