@import "./helpers/breakpoints.scss";
@import "./helpers/variables.scss";

.site-header {
  // sass-lint:disable-block no-vendor-prefixes
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background-color: $blue--dark;
  display: flex;
  height: $site-header-height;
  justify-content: space-between;
  padding-left: 0.25rem;
  padding-right: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $site-header-z-index;

  a {
    color: $white;
    text-decoration: none;

    &:focus {
      color: $blue--light;
      outline: none;
    }

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

.site-header__left {
  align-items: center;
  display: flex;
}

.site-header__logo-link {
  display: block;
  margin-right: 1rem;
  padding: 0.5rem 1rem;

  &:focus {
    background-color: rgba($blue--light, 0.2);
  }
}

.site-header__logo {
  height: 3rem;
  vertical-align: middle;
  width: auto;

  .site-header--menu-is-open & {
    display: none;

    @include breakpoint($for-mobile-up) {
      display: block;
    }
  }
}

.site-header__contact-number {
  color: $white;
  display: none;
  font-size: 1.3rem;
  font-weight: $semibold-font-weight;

  @include breakpoint($for-mobile-up) {
    display: block;
  }

  .site-header--menu-is-open & {
    display: none;

    @include breakpoint($for-tablet-up) {
      display: block;
    }
  }
}

.site-header__menu-toggle {
  background-color: transparent;
  border: 0;
  border-radius: 3px;
  color: $white;
  fill: currentColor;
  font-weight: $base-font-weight;
  padding: 0.5rem 0.5rem 0.4rem;
  transition: transform $mobile-menu-duration $mobile-menu-timing;

  @include breakpoint($for-large-navigation-up) {
    display: none;
  }

  .site-header--menu-is-open & {
    transform: translateX(-($mobile-menu-width));
  }

  &:focus {
    background-color: $blue--light;
    color: $blue--dark;
  }

  &:hover {
    background-color: $white;
    color: $blue--dark;
  }
}
